import { data } from "autoprefixer";
import api from "../api";

export const getPayments = (data) => {
  return api.getApi().get(api.getBaseUrl() + "payments", {
    params: {
      page: data.page,
      pageSize: data.pageSize,
      nameFilter: data.nameFilter,
      invoiceIdFilter: data.invoiceIdFilter,
      countryFilter: data.countryFilter,
      yearFilter: data.yearFilter,
    },
  });
};
export const getPayment = (id) => {
  return api.getApi().get(api.getBaseUrl() + "payments/" + id);
};

export const getCommissions = (id, data) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "payments/" + id + "/commissions", {
      params: {
        page: data.page,
        pageSize: data.pageSize,
        nameFilter: data.nameFilter,
        emailNameFilter: data.emailNameFilter,
        statusFilter: data.statusFilter,
        lucaIdFilter: data.lucaIdFilter,
      },
    });
};
export const getCommission = (id, commissionId) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "payments/" + id + "/commissions/" + commissionId);
};

export const uploadPaymentWithExcel = async (data) => {
  return api.getApi().post(api.getBaseUrl() + "payments/uploadExcel", data);
};

export const sendInvoiceMails = (id) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + "payments/" + id + "/send-invoice-mails");
};

export const sendReminderInvoiceMails = (data) => {
  return api
    .getApi()
    .post(
      api.getBaseUrl() +
        "payments/" +
        data.paymentId +
        "/send-reminder-invoice-mails",
      data.data
    );
};
// Unauthorized request
export const getCommissionUploadDetail = (path) => {
  return api.getApiWithoutToken().get(api.getBaseUrl() + "invoice/" + path);
};

export const postCommissionInvoiceUpload = (data) => {
  return api
    .getApiWithoutToken()
    .post(
      api.getBaseUrl() +
        "invoice/" +
        data.path +
        "/upload" +
        "?dir=paymentPdfs&paymentDir=" +
        data.invoiceId,
      data.data
    );
};
export const postCommissionInvoiceUploadManuel = (data) => {
  console.log(data);
  return api
    .getApiWithoutToken()
    .post(
      api.getBaseUrl() +
        "invoice/" +
        data.commissionId +
        "/uploadManuel" +
        "?dir=paymentPdfs&paymentDir=" +
        data.invoiceId,
      data.data
    );
};
export const downloadAllInvoices = async (id) => {
  return api
    .getApiForBlob()
    .get(api.getBaseUrl() + "payments/" + id + "/downloadAllInvoices");
};
