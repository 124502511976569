import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React from "react";
import {
  getCommission,
  getCommissionUploadDetail,
  getPayment,
  postCommissionInvoiceUpload,
} from "../../../data";

import {
  Link,
  useNavigate,
  useParams,
  useResolvedPath,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import { Button } from "@/components/ui/button";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useRef, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { PDFDownloadLink, PDFViewer, pdf } from "@react-pdf/renderer";
import { InvoicePdfEN } from "@/components";
export function CommissionInvoiceUploadManuelPdfENScreen() {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const [screenLoading, setScreenLoading] = useState(false);
  const [isReadyPdf, setIsReadyPdf] = useState(false);

  const pathname = useResolvedPath().pathname.split("/")[2];
  const navigate = useNavigate();

  const pdfDowloadLinkRef = useRef();

  const { data, isLoading, error } = useQuery({
    queryKey: ["commissionDetail", pathname],

    queryFn: async () => await getCommissionUploadDetail(pathname),
    retry: 1,
  });

  const mutation = useMutation({
    mutationKey: ["uploadPaymentInvoice"],

    mutationFn: (data) => {
      return postCommissionInvoiceUpload(data);
    },
    onSuccess: (response) => {
      setScreenLoading(false);
      //navigate("/payments");
      if (response.status === 200) {
        queryClient.invalidateQueries(["uploadPaymentInvoice", pathname]);
      }
    },
    onError: (error) => {
      setScreenLoading(false);
      toast({
        title: "Error",
        description: "Something went wrong. Please try again later.",
        variant: "destructive",
      });
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const onSubmit = async (values) => {
    setScreenLoading(true);
    console.log(values);
    const { pdfFile } = values;

    var pdfData = {
      invoiceId: data.data.id,
      country: data?.data?.client?.country,

      email: data.data.email,
      lucaId: getLucaId(getValues("bankAccountHolder")),
      recipientName:
        getValues("recipientName") === undefined
          ? ""
          : getValues("recipientName"),
      finalAmount: data.data.finalAmount,
      bankAccountHolder:
        getValues("bankAccountHolder") === undefined
          ? ""
          : getValues("bankAccountHolder"),
      swift: getValues("swift") === undefined ? "" : getValues("swift"),
      iban: getValues("iban") === undefined ? "" : getValues("iban"),
      invoiceDate:
        getValues("invoiceDate") === undefined ? "" : getValues("invoiceDate"),
      phoneNumber:
        getValues("phoneNumber") === undefined ? "" : getValues("phoneNumber"),
    };

    if (
      pdfData.recipientName == undefined ||
      pdfData.bankAccountHolder == undefined ||
      pdfData.iban == undefined ||
      pdfData.phoneNumber == undefined ||
      pdfData.swift == undefined
    ) {
      setScreenLoading(false);
      toast({
        title: "Please fill all required fields",
        message: ``,
        type: "success",
      });
    } else {
      const formData = new FormData();
      formData.append("recipientName", pdfData.recipientName);
      formData.append("bankAccountHolder", pdfData.bankAccountHolder);
      formData.append("iban", pdfData.iban);
      formData.append("swift", pdfData.swift);
      formData.append("phoneNumber", pdfData.phoneNumber);
      formData.append("fileName", data.data.influencerName);
      formData.append("invoiceDate", pdfData.invoiceDate);
      formData.append("invoiceId", pdfData.invoiceId);

      console.log(pdfData);
      var blob = await pdf(InvoicePdfEN({ data: pdfData })).toBlob();
      formData.append("file", blob, "invoice.pdf");

      mutation.mutate({
        path: pathname,
        data: formData,
        invoiceId: data.data.payment.invoiceId,
      });
    }
  };

  const getLucaId = (bankAccountHolder) => {
    if (data.data.client) {
      if (data.data.client.lucaAccountName == bankAccountHolder) {
        return data.data.client.lucaId;
      }
    }
    return "";
  };

  if (isLoading || screenLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[350px] ">
          <CardHeader>
            <CardTitle>Upload Invoice</CardTitle>
            <CardDescription>
              Please wait while we are processing your request
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-center">
              <div
                className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }

  const createPdf = (e) => {
    e.preventDefault();

    setValue(
      "invoiceDate",
      new Intl.DateTimeFormat("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      }).format(new Date())
    );
    var pdfData = {
      invoiceId: data.data.id,
      email: data.data.email,
      lucaId: getLucaId(getValues("bankAccountHolder")),
      country: data?.data?.client?.country,
      recipientName:
        getValues("recipientName") === undefined
          ? ""
          : getValues("recipientName"),
      finalAmount: data.data.finalAmount,
      bankAccountHolder:
        getValues("bankAccountHolder") === undefined
          ? ""
          : getValues("bankAccountHolder"),
      swift: getValues("swift") === undefined ? "" : getValues("swift"),
      iban: getValues("iban") === undefined ? "" : getValues("iban"),
      invoiceDate:
        getValues("invoiceDate") === undefined ? "" : getValues("invoiceDate"),
      phoneNumber:
        getValues("phoneNumber") === undefined ? "" : getValues("phoneNumber"),
    };

    if (
      pdfData.recipientName === "" ||
      pdfData.bankAccountHolder === "" ||
      pdfData.iban === "" ||
      pdfData.phoneNumber === ""
    ) {
      toast({
        title: "Lütfen tüm alanları doldurunuz",
        message: ``,
        type: "success",
      });
    } else {
      setIsReadyPdf(true);
    }
  };
  const handleRefresh = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Card className="w-[350px] ">
          <CardHeader>
            <CardTitle>Error</CardTitle>
            <CardDescription>
              Something went wrong. Please try again later. If the problem
              persists, please contact our support team.
            </CardDescription>
          </CardHeader>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen">
      {data.data.status == "Invoice Uploaded" ? (
        <div className="flex items-center justify-center h-screen">
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>Success</CardTitle>
              <CardDescription>
                Your invoice has been sent successfully. You can close this page
              </CardDescription>
            </CardHeader>
          </Card>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card className="w-[350px] ">
            <CardHeader>
              <CardTitle>Invoice İnformation</CardTitle>
              <CardDescription>
                Please fill out the required fields to complete the payment
                process.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Email</Label>
                  <h1 id="name">{data.data.email}</h1>
                </div>

                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Name Surname</Label>

                  <Input
                    id="recipientName"
                    type="text"
                    onChange={(e) => {
                      setValue("recipientName", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Phone Number</Label>

                  <Input
                    id="phoneNumber"
                    type="text"
                    onChange={(e) => {
                      setValue("phoneNumber", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Invoice Date</Label>
                  <h1 id="name">
                    {new Intl.DateTimeFormat("en-GB", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                    }).format(new Date())}
                  </h1>
                </div>

                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">Bank Account Holder Name</Label>

                  <Input
                    id="bankAccountHolder"
                    type="text"
                    onChange={(e) => {
                      setValue("bankAccountHolder", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">IBAN</Label>

                  <Input
                    id="iban"
                    type="text"
                    onChange={(e) => {
                      setValue("iban", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="framework">
                    SWIFT (can be left blank if not available)
                  </Label>

                  <Input
                    id="swift"
                    type="text"
                    onChange={(e) => {
                      setValue("swift", e.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="name">Invoice Amount</Label>
                  <h1 id="name">
                    {data.data.finalAmount}{" "}
                    {data?.data?.client?.country?.toLowerCase() == "mena"
                      ? "$"
                      : "€"}
                  </h1>
                </div>
                {isReadyPdf && (
                  <div
                    style={{
                      flexDirection: "column",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span>Invoice Created</span>
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        gap: "2px",
                      }}
                    >
                      <Button variant="destructive" asChild>
                        <PDFDownloadLink
                          ref={pdfDowloadLinkRef}
                          document={
                            <InvoicePdfEN
                              data={{
                                invoiceId: data.data.id,
                                email: data.data.email,
                                lucaId: getLucaId(
                                  getValues("bankAccountHolder")
                                ),
                                country: data?.data?.client?.country,
                                recipientName:
                                  getValues("recipientName") === undefined
                                    ? ""
                                    : getValues("recipientName"),
                                finalAmount: data.data.finalAmount,
                                bankAccountHolder:
                                  getValues("bankAccountHolder") === undefined
                                    ? ""
                                    : getValues("bankAccountHolder"),
                                swift:
                                  getValues("swift") === undefined
                                    ? ""
                                    : getValues("swift"),
                                iban:
                                  getValues("iban") === undefined
                                    ? ""
                                    : getValues("iban"),
                                invoiceDate:
                                  getValues("invoiceDate") === undefined
                                    ? ""
                                    : getValues("invoiceDate"),
                                phoneNumber:
                                  getValues("phoneNumber") === undefined
                                    ? ""
                                    : getValues("phoneNumber"),
                              }}
                            />
                          }
                          fileName={data.data.influencerName + ".pdf"}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Invoice"
                          }
                        </PDFDownloadLink>
                      </Button>
                      <Button onClick={(e) => handleRefresh(e)}>
                        Again Invoice Create
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
            <CardFooter className="flex justify-between">
              <div></div>
              {isReadyPdf ? (
                <Button>Approve</Button>
              ) : (
                <Button
                  type="button"
                  variant="outline"
                  onClick={(e) => createPdf(e)}
                >
                  Create Invoice
                </Button>
              )}
            </CardFooter>
          </Card>
        </form>
      )}
    </div>
  );
}
