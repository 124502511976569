export const countryOptions = [
  { value: "Azerbaycan", label: "Azerbaycan" },
  { value: "Mena", label: "Mena" },
  { value: "Almanya", label: "Almanya" },
  { value: "Çekya", label: "Çekya" },
  { value: "Romanya", label: "Romanya" },
  { value: "Yunanistan", label: "Yunanistan" },
  { value: "Macaristan", label: "Macaristan" },
  { value: "Slovakya", label: "Slovakya" },
  // Add more countries as needed
];
